<!-- eslint-disable vue/no-v-html -->
<template>
	<WSelectableDataTable
		:headers="tableHeaders"
		indeterminate-checkbox
		:loading="loading"
		:no-data-text="noDataText"
		:pagination="pagination"
		selected-key="access_status"
		:value="themes"
		@click.native.stop
		@select="changeThemeAssignment($event, true)"
		@select-rows="changeColumnState(true)"
		@unselect="changeThemeAssignment($event, false)"
		@unselect-rows="changeColumnState(false)"
		@update:pagination="onPaginationUpdated($event)"
	>
		<template v-slot:items="{ item }">
			<td>
				<v-layout align-center row>
					<div class="mr-2" :style="`background-color:${item.color};border-radius:50%;height:20px;width:20px`" />
					<span v-html="$highlightMatching(item.title, search)" />
				</v-layout>
			</td>
			<td v-text="item.is_shared ? $t('holding.team-manager.themes.shared') : $t('holding.team-manager.themes.private')" />
			<td v-text="item.permission.write ? $t('holding.team-manager.themes.read_write') : $t('holding.team-manager.themes.read_only')" />
			<td class="text-xs-center">
				<v-tooltip v-if="item.is_default" bottom>
					<template v-slot:activator="{ on }">
						<v-icon style="cursor: default" v-on="on">lock</v-icon>
					</template>
					<span v-text="$t('holding.team-manager.themes.default_theme')" />
				</v-tooltip>
				<span v-else v-text="item.vendor.company" />
			</td>
		</template>
	</WSelectableDataTable>
</template>

<script>
import HoldingUserModuleGuard from '@/mixins/ModulesGuards/Holding/HoldingUserModuleGuard'

export default {
	name: 'HoldingUserSettingsThemesTable',
	components: {
		WSelectableDataTable: () => ({
			component: import('@/components/Commons/Tables/WSelectableDataTable')
		})
	},
	mixins: [HoldingUserModuleGuard],
	inject: ['holdingContext'],
	props: {
		search: {
			default: null,
			required: false,
			type: String
		},
		value: {
			required: true,
			type: Number
		}
	},
	data: function () {
		return {
			loading: false,
			pagination: {
				descending: false,
				page: 1,
				rowsPerPage: 10,
				sortBy: 'title',
				totalItems: 0
			},
			searchTimeout: null,
			tableHeaders: [
				{
					sortable: true,
					text: this.$t('holding.team-manager.themes.labels.title'),
					value: 'title'
				},
				{
					sortable: false,
					text: this.$t('holding.team-manager.themes.labels.shared')
				},
				{
					sortable: false,
					text: this.$t('holding.team-manager.themes.labels.permissions')
				},
				{
					align: 'center',
					sortable: false,
					text: this.$t('holding.team-manager.themes.labels.customer')
				},
				{
					text: ''
				}
			],
			themes: []
		}
	},
	computed: {
		columnState: function () {
			if (this.themes.every(theme => theme.access_status == 'none')) {
				return 'none'
			}
			if (this.themes.some(theme => theme.access_status != 'all')) {
				return 'some'
			}
			return 'all'
		},
		holdingId: function () {
			return this.holdingContext?.holding_id
		},
		noDataText: function () {
			let result = null
			if (this.loading) {
				result = this.$t('holding.team-manager.themes.loading_progress')
			} else if (this.search) {
				result = this.$t('holding.team-manager.themes.no_data_found_for_search', { search: this.search })
			} else {
				result = this.$t('holding.team-manager.themes.no_data_found')
			}
			return result
		},
		selectedUserId: function () {
			return this.value
		}
	},
	watch: {
		holdingId: {
			handler: function (holdingId) {
				if (holdingId) {
					this.loadThemes()
				}
			}
		},
		search: {
			handler: 'onSearchModified'
		}
	},
	mounted: function () {
		if (this.holdingId) {
			this.loadThemes()
		}
	},
	methods: {
		changeColumnState: function (state) {
			const promises = this.themes
				.filter(theme => theme.access_status != state)
				.map(theme => {
					return this.changeThemeAssignment(theme, state == 'all' || state === true)
				})

			return Promise.all(promises).then(() => {
				if (state == 'all' || state === true) {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('holding.team-manager.themes.messages.themes_all'))
				} else {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('holding.team-manager.themes.messages.themes_none'))
				}
			})
		},
		changeThemeAssignment: function (theme, assign) {
			let promise
			if (theme.is_default) {
				if (assign) {
					promise = this.service.assignCatalogTheme(this.holdingId, this.selectedUserId, theme.catalog_theme_id)
				} else {
					promise = this.service.denyCatalogTheme(this.holdingId, this.selectedUserId, theme.catalog_theme_id)
				}
			} else {
				if (assign) {
					promise = this.service.assignTheme(this.holdingId, theme.vendor_id, this.selectedUserId, theme.id)
				} else {
					promise = this.service.denyTheme(this.holdingId, theme.vendor_id, this.selectedUserId, theme.id)
				}
			}

			return promise.then(() => {
				if (assign) {
					theme.access_status = 'all'
				} else {
					theme.access_status = 'none'
				}
			})
		},
		getSortingAlgorithm: function () {
			if (!this.pagination.sortBy) {
				return null
			}
			return (this.pagination.descending ? '-' : '+') + this.pagination.sortBy
		},
		loadThemes: function (filters = {}) {
			this.loading = true
			if (!filters.hasOwnProperty('page')) {
				filters.page = 1
			}
			if (!filters.hasOwnProperty('rowsPerPage')) {
				filters.rowsPerPage = this.pagination.rowsPerPage
			}
			if (filters.hasOwnProperty('search') && !filters.search) {
				delete filters.search
			}
			const sortingAlgorithm = this.getSortingAlgorithm()
			if (sortingAlgorithm) {
				filters.sort = sortingAlgorithm
			}
			return this.service
				.listThemes(this.holdingId, this.value, filters)
				.then(response => {
					if (response.hasOwnProperty('pagination')) {
						const pagination = response.pagination
						const themes = response.data
						this.pagination.page = pagination.currentPage
						this.pagination.rowsPerPage = pagination.perPage
						this.pagination.totalItems = pagination.total
						this.themes = themes
					} else {
						this.pagination.page = 1
						this.pagination.rowsPerPage = -1
						this.themes = response
					}
				})
				.finally(() => {
					this.loading = false
				})
		},
		onPaginationUpdated: function (paginationData) {
			Object.assign(this.pagination, paginationData)
			this.loadThemes({
				page: this.pagination.page,
				rowsPerPage: this.pagination.rowsPerPage,
				search: this.search
			})
		},
		onSearchModified: function () {
			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout)
				this.searchTimeout = null
			}
			this.searchTimeout = setTimeout(
				function () {
					this.pagination.page = 1
					this.loadThemes({
						search: this.search
					})
				}.bind(this),
				1000
			)
		}
	}
}
</script>